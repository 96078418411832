import { lazy } from 'react';

// ----------------------------------------------------------------------

const CityPage = lazy(() => import('src/pages/island/city'));
const CitiesPage = lazy(() => import('src/pages/island/cities'));

// ----------------------------------------------------------------------

export const islandRoutes = [
  {
    path: 'island',
    children: [
      { path: 'cities', element: <CitiesPage /> },
      { path: 'city/:city', element: <CityPage /> },
    ],
  },
];
