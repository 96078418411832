import { lazy } from 'react';

import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const DiscoverPage = lazy(() => import('src/pages/discover/post'));
const DiscoversPage = lazy(() => import('src/pages/discover/posts'));

// ----------------------------------------------------------------------

export const discoverRoutes = [
  {
    path: 'discover',
    children: [
      { path: paths.discover.root, element: <DiscoversPage /> },
      { path: ':slug', element: <DiscoverPage /> },
    ],
  },
];
