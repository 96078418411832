import { _mock } from './_mock';
import { _tags } from './assets';

// ----------------------------------------------------------------------

// const content = (name: string) => `
// <p>Pellentesque posuere. Phasellus a est. Suspendisse pulvinar, augue ac venenatis condimentum, sem libero volutpat nibh, nec pellentesque velit pede quis nunc.</p>
// <br/>

// <p>Pellentesque posuere. Phasellus a est. Suspendisse pulvinar, augue ac venenatis condimentum, sem libero volutpat nibh, nec pellentesque velit pede quis nunc. Phasellus viverra nulla ut metus varius laoreet. Praesent egestas tristique nibh. Donec posuere vulputate arcu. Quisque rutrum.</p>
// <br/>

// <p>Donec posuere vulputate arcu. Quisque rutrum. Curabitur vestibulum aliquam leo. Nam commodo suscipit quam. Vestibulum ullamcorper mauris at ligula.</p>
// <br/>

// <p>Pellentesque posuere. Phasellus a est. Suspendisse pulvinar, augue ac venenatis condimentum, sem libero volutpat nibh, nec pellentesque velit pede quis nunc. Phasellus viverra nulla ut metus varius laoreet. Praesent egestas tristique nibh.</p>

// <br/>
// <br/>
// <br/>
// <p><img alt="alt marketing" src="/assets/images/${name}/${name}_post_01.jpg" /></p>
// <br/>
// <br/>
// <br/>

// <h4>Curabitur suscipit suscipit tellus</h4>
// <br/>

// <p>Donec posuere vulputate arcu. Quisque rutrum. Curabitur vestibulum aliquam leo. Nam commodo suscipit quam. Vestibulum ullamcorper mauris at ligula.</p>
// <br/>

// <h4>Nullam accumsan lorem in</h4>
// <br/>

// <p>Donec posuere vulputate arcu. Quisque rutrum. Curabitur vestibulum aliquam leo. Nam commodo suscipit quam. Vestibulum ullamcorper mauris at ligula.</p>
// <br/>

// <p>Donec posuere vulputate arcu. Quisque rutrum. Curabitur vestibulum aliquam leo.</p>

// <br/>
// <br/>
// <br/>
// <p><img alt="alt marketing" src="/assets/images/${name}/${name}_post_02.jpg" /></p>
// <br/>
// <br/>
// <br/>

// <p>Donec posuere vulputate arcu. Quisque rutrum. Curabitur vestibulum aliquam leo. Nam commodo suscipit quam. Vestibulum ullamcorper mauris at ligula.</p>
// <br/>

// <p>Pellentesque posuere. Phasellus a est. Suspendisse pulvinar, augue ac venenatis condimentum, sem libero volutpat nibh, nec pellentesque velit pede quis nunc. Phasellus viverra nulla ut metus varius laoreet. Praesent egestas tristique nibh.</p>
// <br/>

// <p>Donec posuere vulputate arcu. Quisque rutrum. Curabitur vestibulum aliquam leo. Nam commodo suscipit quam. Vestibulum ullamcorper mauris at ligula.</p>
// `;

const content = (name: string) =>
  `${name}<p>Brimming with character, this historic oasis is an intriguing glimpse into the past. The city&rsquo;s most well-known feature is perhaps the 4.5 km Venetian Wall that was built to encircle the city, furnished with eleven bastions and three city gates.</p><br/><p>Constructed in the 16th century, and still in excellent condition, the walls surround the Old Town keeping the contents preserved like a time capsule.</p><br/><br/><p><img style="width:100%" src="https://directus.ercangurevin.com/assets/ef5c778f-f0f0-4814-bcf8-d0f58125cc24" alt="Lefkosa Cover" /></p><br/><p>Little has changed in the Old Town for many years and a stroll through its narrow, meandering streets will see you appreciate the calm and relaxed atmosphere as you admire the wonderful architectural treasures.</p><br/><br/><p>The&nbsp;<a href="https://www.visitncy.com/discover/the-great-inn/" target="_blank" rel="noopener noreferrer"><u>Buyuk Han</u></a>, the &lsquo;Great Inn&rsquo; was built by the Ottomans in 1572 and is today a beautiful courtyard with artisan shops and caf&eacute;s. From the&nbsp;<a href="https://www.visitncy.com/discover/selimiye-mosque/" target="_blank" rel="noopener noreferrer"><u>Selimiye Mosque</u></a>&nbsp;with its gothic cathedral exterior to a 400-year-old&nbsp;<u>Great Hammam</u>&nbsp;Turkish bath house, there are numerous historical sights to marvel at.</p><br/><br/><p>With much to discover and experience here you will sure to be enamoured by this city&rsquo;s timeless charm.</p>`;

const base = (index: number) => ({
  id: _mock.id(index),
  title: _mock.postTitle(index),
  description: _mock.description(index),
  category: 'Marketing',
  favorited: _mock.boolean(index),
  createdAt: _mock.time(index),
  duration: '8 minutes read',
  tags: _tags.slice(index + 1, index + 2),
  author: {
    name: _mock.fullName(index),
    role: _mock.role(index),
    avatarUrl: _mock.image.avatar(index),
    quotes: 'Member since Mar 15, 2021',
    about:
      'Integer tincidunt. Nullam dictum felis eu pede mollis pretium. Maecenas ullamcorper, dui et placerat feugiat, eros pede varius nisi, condimentum viverra felis nunc et lorem.',
  },
});

// ----------------------------------------------------------------------

export const _marketingPosts = [...Array(12)].map((_, index) => ({
  ...base(index),
  content: content('marketing'),
  coverUrl: _mock.image.marketing(index),
  heroUrl: `/assets/images/marketing/marketing_post_hero.jpg`,
}));

export const _travelPosts = [...Array(12)].map((_, index) => ({
  ...base(index),
  content: content('travel'),
  coverUrl: _mock.image.travel(index),
  heroUrl: `/assets/images/travel/travel_post_hero.jpg`,
}));

export const _careerPosts = [...Array(12)].map((_, index) => ({
  ...base(index),
  content: content('career'),
  coverUrl: _mock.image.career(index),
  heroUrl: `/assets/images/career/career_post_hero.jpg`,
}));

export const _coursePosts = [...Array(12)].map((_, index) => ({
  ...base(index),
  content: content('course'),
  coverUrl: _mock.image.course(index),
  heroUrl: `/assets/images/course/course_post_hero.jpg`,
}));
