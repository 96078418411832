import { lazy } from 'react';

import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const MagazinesPage = lazy(() => import('src/pages/magazine'));

// ----------------------------------------------------------------------

export const magazineRoutes = [
  {
    path: 'magazine',
    children: [{ path: paths.magazine.root, element: <MagazinesPage /> }],
  },
];
