import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export const pageLinks = [
  {
    order: '1',
    subheader: 'Island',
    items: [
      { title: 'Nicosia', path: paths.island.cities.nicosia },
      { title: 'Kyrenia', path: paths.island.cities.kyrenia },
      { title: 'Famagusta', path: paths.island.cities.famagusta },
      { title: 'Trikomo', path: paths.island.cities.trikomo },
      { title: 'Omorfo', path: paths.island.cities.omorfo },
      { title: 'Lefke', path: paths.island.cities.lefke },
    ],
  },
  {
    order: '2',
    subheader: 'Essentials',
    items: [
      { title: 'Global Offices', path: paths.essentials.globalOffices },
      { title: 'Climate & Weather', path: paths.essentials.post('climate-weather') },
      { title: 'Language & Religion', path: paths.essentials.post('language-religion') },
      { title: 'Money & Currency', path: paths.essentials.post('money-currency') },
      { title: 'Public Holidays', path: paths.essentials.post('public-holidays') },
      { title: 'Health & Safety', path: paths.essentials.post('health-safety') },
      { title: 'Transportation', path: paths.essentials.post('transportation') },
      { title: "Other FAQ's", path: paths.essentials.post('faqs') },
    ],
  },
];

const cities = [
  {
    order: '1',
    subheader: 'Nicosia',
    cover: '/assets/images/cities/lefkosa.jpg',
    path: paths.island.cities.nicosia,
    // items: [{ title: 'Landing', path: paths.island.city }],
  },
  {
    order: '2',
    subheader: 'Kyrenia',
    cover: '/assets/images/cities/girne.jpg',
    path: paths.island.cities.kyrenia,
  },
  {
    order: '3',
    subheader: 'Famagusta',
    cover: '/assets/images/cities/gazimagusa.jpg',
    path: paths.island.cities.famagusta,
  },
  {
    order: '4',
    subheader: 'Trikomo',
    cover: '/assets/images/cities/iskele.jpg',
    path: paths.island.cities.trikomo,
  },
  {
    order: '5',
    subheader: 'Omorfo',
    cover: '/assets/images/cities/guzelyurt.jpg',
    path: paths.island.cities.omorfo,
  },
  {
    order: '6',
    subheader: 'Lefke',
    cover: '/assets/images/cities/lefke.jpg',
    path: paths.island.cities.lefke,
  },
  {
    order: '7',
    subheader: 'Essentials',
    items: [
      { title: 'Global Offices', path: paths.essentials.globalOffices },
      { title: 'Climate & Weather', path: paths.essentials.post('climate-weather') },
      { title: 'Language & Religion', path: paths.essentials.post('language-religion') },
      { title: 'Money & Currency', path: paths.essentials.post('money-currency') },
      { title: 'Public Holidays', path: paths.essentials.post('public-holidays') },
      { title: 'Health & Safety', path: paths.essentials.post('health-safety') },
      { title: 'Transportation', path: paths.essentials.post('transportation') },
      { title: "Other FAQ's", path: paths.essentials.post('faqs') },
    ],
  },
];

export const navConfig = [
  { title: 'Home', path: '/' },
  { title: 'The Island', path: '/island', children: cities.map((city) => city) },
  { title: 'Magazine', path: '/magazine' },
  { title: 'About', path: '/about' },
  { title: 'Contact', path: '/contact' },
];
