import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// icons
import contentDeliveryNetwork from '@iconify/icons-carbon/content-delivery-network';

// @mui
import { Box, Popover, SxProps, MenuItem } from '@mui/material';

// components
import Iconify from '../components/iconify';
import { IconButtonAnimate } from '../components/animate';

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'Türkçe',
    value: 'tr',
  },
  {
    label: 'English',
    value: 'en',
  },
];

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps;
};

export default function LanguagePopover({ sx }: Props) {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(localStorage.getItem('i18nextLng') || 'en');

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (newLang: string) => {
    localStorage.setItem('i18nextLng', newLang);
    i18n.changeLanguage(newLang);
    handleClose();
    setCurrentLang(newLang);
    // dayjs.locale(newLang);
  };

  return (
    <>
      <IconButtonAnimate color="inherit" onClick={handleOpen} sx={sx}>
        <Iconify icon={contentDeliveryNetwork} sx={{ width: 20, height: 20 }} />
      </IconButtonAnimate>

      <Popover
        open={Boolean(open)}
        onClose={handleClose}
        anchorEl={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {LANGS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === currentLang}
            onClick={() => handleChangeLang(option.value)}
            sx={{ my: 1 }}
          >
            <Box
              component="img"
              alt={option.label}
              src={`/assets/images/flags/${option.value}.png`}
              sx={{ borderRadius: '50%', width: 28, height: 28, objectFit: 'cover', mr: 1 }}
            />

            {option.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}
