import { lazy } from 'react';

// ----------------------------------------------------------------------

const AboutPage = lazy(() => import('src/pages/about'));
const ContactPage = lazy(() => import('src/pages/contact'));

// ----------------------------------------------------------------------

export const otherRoutes = [
  {
    path: '/',
    children: [
      { path: 'contact', element: <ContactPage /> },
      { path: 'about', element: <AboutPage /> },
    ],
  },
];
