import { lazy } from 'react';

import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const GlobalOfficesPage = lazy(() => import('src/pages/essentials/global-offices'));
const EssentialPostPage = lazy(() => import('src/pages/essentials/essential-post'));

// ----------------------------------------------------------------------

export const essentialRoutes = [
  {
    path: 'essentials',
    children: [
      { path: paths.essentials.globalOffices, element: <GlobalOfficesPage /> },
      { path: ':id', element: <EssentialPostPage /> },
    ],
  },
];
