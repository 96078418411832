import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import MainLayout from 'src/layouts/main';
import { islandRoutes } from 'src/routes/sections/island';
import { SplashScreen } from 'src/components/loading-screen';
import { magazineRoutes } from 'src/routes/sections/magazine';
import { discoverRoutes } from 'src/routes/sections/discover';
import { essentialRoutes } from 'src/routes/sections/essentials';

import { otherRoutes } from './other';
import { errorRoutes } from './error';
import { commonRoutes } from './common';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/home'));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      element: (
        <MainLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      ),
      children: [
        { element: <IndexPage />, index: true },

        ...otherRoutes,

        ...islandRoutes,

        ...essentialRoutes,

        ...discoverRoutes,

        ...magazineRoutes,
      ],
    },

    ...errorRoutes,

    ...commonRoutes,

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
