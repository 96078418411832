// ----------------------------------------------------------------------

export const paths = {
  contact: '/contact',
  about: '/about',
  island: {
    root: '/island',
    cities: {
      root: '/island/cities',
      nicosia: '/island/city/lefkosa',
      kyrenia: '/island/city/girne',
      famagusta: '/island/city/gazimagusa',
      trikomo: '/island/city/iskele',
      omorfo: '/island/city/guzelyurt',
      lefke: '/island/city/lefke',
    },
    city(id: string): string {
      return `/island/city/${id}`;
    },
  },
  // Essentials
  essentials: {
    root: '/essentials',
    globalOffices: '/essentials/global-offices',
    // climateWeather: '/essentials/climate-weather',
    post(id: string): string {
      return `/essentials/${id}`;
    },
  },
  discover: {
    root: '/discover',
    discover(slug: string): string {
      return `/discover/${slug}`;
    },
  },
  // Magazine
  magazine: {
    root: '/magazine',
    magazine(no: number): string {
      return `/magazine/${no}`;
    },
  },

  // Common
  maintenance: '/maintenance',
  comingsoon: '/coming-soon',
  page404: '/404',
  page500: '/500',
};
